<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Workflow Configuration" icon="fa-solid fa-gear" />

    <div class="generalBorderlessBox">
        <div class="row mb-3">
            <div class="col-12 mb-2">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" :class="curTab === 'general' ? 'active' : ''" data-bs-toggle="pill" href="#general" @click="curTab = 'general'">General</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="curTab === 'running' ? 'active' : ''"  data-bs-toggle="pill" href="#running" @click="curTab = 'running'">Running No</a>
                    </li>
                </ul>

                <div class="tab-content mt-4">
                    <!-- General -->
                    <div class="tab-pane container p-0" :class="curTab === 'general' ? 'active' : ''" id="general">
                        <div class="contentBox border shadow-sm">
                            <div class="row">
                                <div class="col-12 fw-bold mb-2">General</div>
                                <div class="col-12">
                                    <table border="0">
                                        <tbody>
                                            <tr>
                                                <td class="pt-1">
                                                    <div class="form-check form-switch">
                                                        <input data-bs-toggle="modal" data-bs-target="#mdlAllowExternal" class="form-check-input" type="checkbox" v-model="enableExternalSignee" value="true" id="eSignee">
                                                    </div>
                                                </td>
                                                <td class="ps-2">Allow external signee</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of General-->

                    <!-- Running No -->
                    <div class="tab-pane container p-0" :class="curTab === 'running' ? 'active' : 'fade'" id="running">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="generalContentBox border shadow-sm">
                                    <div class="row">
                                        <div class="col-6 fw-bold mb-2">Running No</div>
                                        <div class="col-6 mb-2 text-end text-primary isLink" @click="isEditRunningNo = false; runPrefix = null; runInitialNo = null" data-bs-toggle="modal" data-bs-target="#mdlRunningNo">
                                            <fa icon="hashtag" />
                                            <span class="me-2" style="font-size: 14px">+</span>New running no
                                        </div>
                                        <div class="col-12">
                                            <table class="table table-hover" border="0">
                                                <tbody>
                                                    <tr v-for="r in arrRun" :key="r">
                                                        <td :class="runningId === r.prefix ? 'table-active' : ''">
                                                            <div class="row">
                                                                <div class="col-6 col-lg-3 fw-bold text-primary isLink" @click="runningId = r.prefix">{{ r.prefix }}</div>
                                                                <div class="col-6 col-lg-3 fw-bold text-primary isLink" @click="runningId = r.prefix">{{ r.number }}</div>
                                                                <div class="col-6 col-lg-4 d-none d-md-block hoverShow">
                                                                    <span  data-bs-toggle="modal" data-bs-target="#mdlAddWorkflow"  class="text-grey isLink me-3">
                                                                        <Popper class="popperDark" arrow hover content="Add workflow">
                                                                            <span>
                                                                                <fa icon="sitemap" size="lg" />
                                                                                <span class="me-2" style="font-size: 14px">+</span>
                                                                            </span>
                                                                        </Popper>
                                                                    </span>
                                                                    <span @click="isEditRunningNo = true; runPrefix = r.prefix; runInitialNo = r.number" data-bs-toggle="modal" data-bs-target="#mdlRunningNo" class="text-grey isLink me-4">
                                                                        <Popper class="popperDark" arrow hover content="Edit running no">
                                                                            <fa icon="pen" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                    <span data-bs-toggle="modal" data-bs-target="#mdlDelTeam" class="text-grey isLink me-4">
                                                                        <Popper class="popperDark" arrow hover content="Delete running no">
                                                                            <fa icon="trash" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                </div>
                                                                <div class="col-6 col-lg-2 text-end">
                                                                    <span class="badge bg-grey">
                                                                        <fa icon="sitemap" class="me-1" />{{r.workflow}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-6 text-end d-block d-md-none">
                                                                    <span  data-bs-toggle="modal" data-bs-target="#mdlAddWorkflow"  class="text-grey isLink me-3">
                                                                        <Popper class="popperDark" arrow hover content="Add workflow">
                                                                            <span>
                                                                                <fa icon="sitemap" size="lg" />
                                                                                <span class="me-2" style="font-size: 14px">+</span>
                                                                            </span>
                                                                        </Popper>
                                                                    </span>
                                                                    <span @click="isEditRunningNo = true; runPrefix = r.prefix; runInitialNo = r.number" data-bs-toggle="modal" data-bs-target="#mdlRunningNo" class="text-grey isLink me-4">
                                                                        <Popper class="popperDark" arrow hover content="Edit running no">
                                                                            <fa icon="pen" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                    <span data-bs-toggle="modal" data-bs-target="#mdlDelTeam" class="text-grey isLink me-4">
                                                                        <Popper class="popperDark" arrow hover content="Delete running no">
                                                                            <fa icon="trash" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="runningId !== null" class="col-lg-5">
                                <div class="generalContentBox border shadow-sm">
                                    <div class="row">
                                        <div class="col-12 fw-bold mb-2">Workflow</div>
                                        <div class="col-12">
                                            <table class="table table-hover">
                                                <tbody>
                                                    <tr v-for="w in arrWorkflow" :key="w">
                                                        <td>
                                                            <div>{{w.name}}</div>
                                                            <div class="text-grey small">{{w.step}} steps</div>
                                                        </td>
                                                        <td class="text-end text-grey isLink">
                                                            <Popper class="popperDark" arrow hover content="Remove running no">
                                                            <fa icon="trash" size="lg" />
                                                            </Popper>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of Running No -->
                </div>
            </div>

            <div class="col-12">
                <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/workflow' })">
                    <fa icon="circle-arrow-left" size="lg" /> Back
                </button>
            </div>
        </div>
    </div>

    <!-- Modal: AddWorkflow --> 
    <div
        class="modal fade" 
        id="mdlAddWorkflow"
        tabindex="-1"
        aria-labelledby="AddWorkflow"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add workflow</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="form-floating">
                        <select v-model="workflowId" id="workflowId" class="form-select">
                            <option value="">Select Workflow</option>
                            <option value="1">Change Request - AccMgr</option>
                            <option value="2">Change Request - Developer</option>
                            <option value="3">Deployment form - Developer</option>
                        </select>
                        <label for="workflowId">Workflow</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal: NewRunning --> 
    <div
        class="modal fade" 
        id="mdlRunningNo"
        tabindex="-1"
        aria-labelledby="NewRunning"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="isEditRunningNo === true" class="modal-title" id="exampleModalLabel"> Edit running no</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> New running no</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" v-model="runPrefix" id="runPrefix" placeholder="Prefix">
                            <label for="runPrefix">Prefix</label>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" v-model="runInitialNo" id="runInitialNo" placeholder="runInitialNo">
                            <label for="runInitialNo">Initial Number</label>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button v-if="isEditRunningNo === true" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Update
                    </button>
                    <button v-else type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal: Confirmation to allow external --> 
    <div
        class="modal fade" 
        id="mdlAllowExternal"
        tabindex="-1"
        aria-labelledby="AllowExternal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    Confirm to <span v-if="enableExternalSignee === true">allow</span><span v-else>disallow</span> external signee in the workflow?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'

export default {
    name: 'WorkFlowConfig',
    components: { TopNavigation, Alert, Popper },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const curTab = ref('general')
        const enableExternalSignee = ref(false)
        const eSignee = ref(null)

        const runningId = ref(null)
        const runPrefix = ref(null)
        const runInitialNo = ref(null)
        const isEditRunningNo = ref(false) // true/false (edit/add new)
        const workflowId = ref('')
        const arrRun = ref([])
        const arrWorkflow = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([runningId], () => {
            console.info('- - WATCH', runningId.value)
            if (runningId.value !== null) {
                // hardcode
                if(runningId.value === "CR_") {
                    arrWorkflow.value = [
                        {
                            "name":"Change Request - AccMgr",
                            "step": 12
                        },
                        {
                            "name":"Change Request - Developer",
                            "step": 8
                        },
                    ]
                }

                if(runningId.value === "D_") {
                     arrWorkflow.value = [
                        {
                            "name":"Deployment form - Developer",
                            "step": 5
                        }
                     ]
                }
            }
        })

        onMounted(() => {
            arrRun.value = [
                {
                    "prefix": "CR_",
                    "number": 20230,
                    "workflow": 2
                },
                {
                    "prefix": "D_",
                    "number": 1,
                    "workflow": 3
                }
            ]
        })

        return {
            route, router, alert, closeAlert, func, curTab, 
            enableExternalSignee, eSignee, 
            arrRun, arrWorkflow, runningId, runPrefix, runInitialNo, isEditRunningNo, workflowId
        }
    }


}
</script>

<style>

</style>